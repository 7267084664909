import React from 'react'
import PortableText from './portableText'
import Container from './container'

import styles from './blog-post.module.css'

function Product (props) {
  const {_rawBody, name} = props
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{name}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </Container>
    </article>
  )
}

export default Product
