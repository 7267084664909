import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Product from '../components/product'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {toPlainText} from '../lib/helpers'

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: {eq: $id}) {
      id
      icon {
        ...SanityImage
        alt
      }
      name
      slug {
        current
      }
      excerpt
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`

const ProductTemplate = props => {
  const {data, errors} = props
  const product = data && data.product
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {product && <SEO title={product.name || 'Tjeneste'} description={product.excerpt} image={product.icon} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {product && <Product {...product} />}
    </Layout>
  )
}

export default ProductTemplate
